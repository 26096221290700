<template>
  <a-layout style="height: 100%; background-color: white">
    <a-layout-sider
      v-if="this.$router.currentRoute.name !== 'login'"
      style="background-color: white"
    >
      <a-button type="danger" style="width: 100%" @click="logout"
        >Выйти</a-button
      >
      <a-layout-header style="background-color: white">
        <span style="font-size: larger">CheckSkin</span>
      </a-layout-header>
      <div></div>
      <a-menu mode="inline" :default-selected-keys="['2']">
        <a-menu-item key="2">
          <router-link to="/novosti"
            ><a-icon type="pic-left" />Новости</router-link
          >
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/lpu"><a-icon type="bank" />ЛПУ</router-link>
        </a-menu-item>
        <a-menu-item key="4">
          <router-link to="/reviews"
            ><a-icon type="message" />Отзывы</router-link
          >
        </a-menu-item>
        <a-menu-item key="5">
          <router-link to="/documents"
            ><a-icon type="apartment" />Пакеты документов</router-link
          >
        </a-menu-item>
        <a-menu-item key="6">
          <router-link to="/statistics"
            ><a-icon type="bar-chart" />Статистика</router-link
          >
        </a-menu-item>
        <a-menu-item key="7">
          <router-link to="/broadcast"
            ><a-icon type="notification" />Уведомления</router-link
          >
        </a-menu-item>
        <a-menu-item key="8">
          <router-link to="/settings"
            ><a-icon type="setting" />Настройки</router-link
          >
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content>
        <router-view />
      </a-layout-content>
      <!-- <a-layout-footer>AXAS Soft</a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["resetLoginStates"]),
    logout() {
      this.resetLoginStates();
      this.$router.push("/login");
    },
  },
};
</script>
