import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/playground",
    name: "playground",
    component: () => import("../views/ReviewsListVueTable.vue"),
  },
  {
    path: "",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "novosti",
      },
      {
        path: "settings",
        meta: { requiresAuth: true },
        component: () => import("../views/Setting.vue"),
      },
      {
        path: "novosti",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: { requiresAuth: true },
        component: () => import("../views/News.vue"),
        children: [
          {
            path: "",
            // name: 'novosti.list',
            component: () => import("../views/NewsList.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "add",
            name: "novosti.add",
            component: () => import("../views/NewsAdd.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "edit/:id",
            name: "novosti.edit",
            component: () => import("../views/NewsEdit.vue"),
            props: true,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "lpu",
        component: () => import("../views/Lpu.vue"),
        children: [
          {
            path: "",
            // name: 'lpu.list',
            component: () => import("../views/LpuList.vue"),
          },
          {
            path: "add",
            name: "lpu.add",
            component: () => import("../views/LpuAdd.vue"),
          },
          {
            path: "edit/:id",
            name: "lpu.edit",
            component: () => import("../views/LpuEdit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/reviews",
        component: () => import("../views/Reviews.vue"),
        children: [
          {
            path: "",
            redirect: "pending",
          },
          {
            path: "pending",
            name: "reviews.pending",
            component: () => import("../views/ReviewsPending.vue"),
          },
          {
            path: "list",
            name: "reviews.list",
            component: () => import("../views/ReviewsList.vue"),
          },
        ],
      },
      {
        path: "/documents",
        component: () => import("../views/Documents.vue"),
        children: [
          {
            path: "",
            redirect: "list",
          },
          {
            path: "list",
            name: "documents.list",
            component: () => import("../views/DocumentsList.vue"),
          },
          {
            path: "add",
            name: "documents.add",
            component: () => import("../views/DocumentsAdd.vue"),
          },
          {
            path: "edit",
            name: "documents.edit",
            component: () => import("../views/DocumentsEdit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/triggery",
        name: "",
        component: () => import("../views/Triggers.vue"),
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("../views/Statistics.vue"),
        children: [
          {
            path: "",
            redirect: "diagnoses",
          },
          {
            path: "regions",
            name: "statistics.regions",
            component: () => import("../views/StatisticsListRegions.vue"),
          },
          {
            path: "diagnoses",
            name: "statistics.diagnoses",
            component: () => import("../views/StatisticsListDiagnoses.vue"),
          },
        ],
      },
      {
        path: "/broadcast",
        name: "broadcast",
        component: () => import("../views/Broadcast.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
