<template>
  <div
    style="
      margin-left: auto;
      margin-right: auto;
      width: 20rem;
      margin-top: 20rem;
    "
  >
    <span style="font-size: larger">CheckSkin</span>
    <a-form-model
      layout="horizontal"
      :model="formInline"
      @submit="loginSubmit"
      @submit.native.prevent
    >
      <a-form-model-item>
        <a-input v-model="formInline.username" placeholder="Логин" allow-clear>
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-input-password
          v-model="formInline.password"
          type="password"
          placeholder="Пароль"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          :disabled="formInline.username === '' || formInline.password === ''"
        >
          Войти
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      formInline: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState(["loggingIn", "loginError", "accessToken"]),
  },
  methods: {
    ...mapActions(["doLogin"]),
    loginSubmit() {
      this.doLogin({
        login: this.formInline.username,
        password: this.formInline.password,
      });
    },
  },
};
</script>

<style scoped></style>
