import Vue from "vue";
import Vuex from "vuex";
import Antd from "ant-design-vue";
import App from "./App.vue";
import createPersistedState from "vuex-persistedstate";
import router from "./router";
import "ant-design-vue/dist/antd.css";
import axios from "axios";
import "@toast-ui/editor/dist/toastui-editor.css";

import IdleVue from "idle-vue";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = "https://api.skinallergic.ru/";
axios.defaults.headers.post["Accept"] = "application/json";
// axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

const token = localStorage.getItem("Token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

Vue.use(Antd);

axios.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `Token ${localStorage.getItem("Token")}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // всё что не response status [200-299] попадет сюда
    console.log(error.response.data.errors[0].message);
    if (
      error.response.status === 401 &&
      error.response.data.errors[0].message === "Token Expired"
    ) {
      //
      console.log(error);
      router.push("/login");
    }
    return Promise.reject(error.response.data);
  }
);

Vue.prototype.$http = axios;

Vue.use(Vuex);

Vue.config.productionTip = false;

const getDefaultState = () => {
  return {
    token: null,
    refreshToken: null,
    loggingIn: false,
    loginError: null,
    accessTokenExpireAt: null,
    refreshTokenExpireAt: null,
  };
};

const store = new Vuex.Store({
  state: {
    token: null,
    refreshToken: null,
    loggingIn: false,
    loginError: null,
    accessTokenExpireAt: null,
    refreshTokenExpireAt: null,
  },
  mutations: {
    loginStart: (state) => (state.loggingIn = true),
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.token = accessToken;
    },
    updateAccessTokenExpireAt: (state, expire_at) => {
      state.accessTokenExpireAt = expire_at;
    },
    updateRefreshTokenExpireAt: (state, expire_at) => {
      state.refreshTokenExpireAt = expire_at;
    },
    updateRefreshToken: (state, refreshToken) => {
      state.token = refreshToken;
    },
    resetStates: (state) => {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    doLogin({ commit }, user_data) {
      return new Promise((resolve, reject) => {
        console.log("asd", user_data);
        commit("loginStart");
        axios
          .post("/cp/login/", {
            login: user_data.login,
            password: user_data.password,
          })
          .then((response) => {
            let token = response.data.data.tokens.access.value;
            let access_expire_at = response.data.data.tokens.access.expire_at;
            let refresh_token = response.data.data.tokens.refresh.value;
            let refresh_expire_at = response.data.data.tokens.refresh.expire_at;
            localStorage.setItem("Token", token);
            axios.defaults.headers.common["Authorization"] = `Token ${token}`;
            commit("loginStop", null);
            commit("updateAccessToken", token);
            commit("updateAccessTokenExpireAt", access_expire_at);
            commit("updateRefreshToken", refresh_token);
            commit("updateRefreshTokenExpireAt", refresh_expire_at);
            router.push("/novosti");
            resolve();
          })
          .catch((error) => {
            commit("loginStop", error);
            // commit("updateAccessToken", null);
            reject(error);
          });
      });
    },
    fetchAccessToken({ commit }) {
      commit("updateAccessToken", localStorage.getItem("Token"));
    },
    resetLoginStates({ commit }) {
      commit("resetStates");
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    accessWillExpireAt: (state) => state.accessTokenExpireAt,
    refreshWillExpireAt: (state) => state.refreshTokenExpireAt,
    refreshToken: (state) => state.refreshToken,
  },
  plugins: [createPersistedState()],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

const timeConvertMinToMsec = (min) => {
  return min * 60 * 1000;
};
const minuntes = 30; // 30m time of timeout logout
// const minuntes = 0.1; // 10s time of timeout logout
const idleTimeout = timeConvertMinToMsec(minuntes);
// const timeConvertMsToMin = (time) => {
//   return (time / 60 / 1000).toFixed(2)
// }

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: idleTimeout,
  startAtIdle: false,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  onIdle() {
    this.$store.dispatch("resetLoginStates");
    alert(`Вы были неактивны ${minuntes}мин.`);
    router.push("/login");
  },
}).$mount("#app");
